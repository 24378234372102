.PaymentHistoryTable .card {
  margin-bottom: 30px;
  border: 0;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
}
.PaymentHistoryTable .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0,0,0,.05);
  border-radius: .375rem;
  background-color: #fff;
  background-clip: border-box;
}


.PaymentHistoryTable .card-header:first-child {
  border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0;
}

.PaymentHistoryTable .border-0 {
  border: 0!important;
}
.PaymentHistoryTable .card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(0,0,0,.05);
  background-color: #fff;
}

.PaymentHistoryTable .table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.PaymentHistoryTable .card .table {
  margin-bottom: 0;
}

.PaymentHistoryTable .table-flush tbody tr:first-child td, .PaymentHistoryTable .table-flush tbody tr:first-child th {
  border-top: 0;
}
.PaymentHistoryTable .card .table td, .PaymentHistoryTable .card .table th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.PaymentHistoryTable .table.align-items-center td, .PaymentHistoryTable .table.align-items-center th {
  vertical-align: middle;
}

.PaymentHistoryTable .table-flush td, .PaymentHistoryTable .table-flush th {
  border-right: 0;
  border-left: 0;
}
.PaymentHistoryTable .table td, .PaymentHistoryTable .table th {
  font-size: .8125rem;
  white-space: nowrap;
}
.PaymentHistoryTable .table th {
  font-weight: 600;
}
.PaymentHistoryTable .table td, .PaymentHistoryTable .table th {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.PaymentHistoryTable th {
  text-align: inherit;
}

.PaymentHistoryTable .avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: .375rem;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}

.PaymentHistoryTable .mr-3, .mx-3 {
  margin-right: 1rem!important;
}
.PaymentHistoryTable .avatar.rounded-circle img, .PaymentHistoryTable .rounded-circle {
  border-radius: 50%!important;
}
.PaymentHistoryTable a {
  text-decoration: none;
  color: #5e72e4;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}


.PaymentHistoryTable .mb-0, .my-0 {
  margin-bottom: 0!important;
}

.PaymentHistoryTable .h3, h3 {
  font-size: 1.0625rem;
}
.PaymentHistoryTable .h1, .PaymentHistoryTable .h2, .PaymentHistoryTable .h3, .PaymentHistoryTable .h4, .PaymentHistoryTable .h5, .PaymentHistoryTable .h6, .PaymentHistoryTable h1, .PaymentHistoryTable h2, .PaymentHistoryTable h3, .PaymentHistoryTable h4, .PaymentHistoryTable h5, .PaymentHistoryTable h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: .5rem;
  color: #32325d;
}

@media (max-width: 500px){
  .PaymentHistoryTable h3{
    font-size: 5vw;
  }
}
.PaymentHistoryTable h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.PaymentHistoryTable .avatar.rounded-circle img, .PaymentHistoryTable .rounded-circle {
  border-radius: 50%!important;
}

.PaymentHistoryTable .avatar img {
  width: 100%;
  border-radius: .375rem;
}
.PaymentHistoryTable img {
  vertical-align: middle;
  border-style: none;
}

.PaymentHistoryTable .table-flush tbody tr:first-child td, .PaymentHistoryTable .table-flush tbody tr:first-child th {
  border-top: 0;
}

.PaymentHistoryTable .card .table td, .PaymentHistoryTable .card .table th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.PaymentHistoryTable .PaymentHistoryTable .table.align-items-center td, .PaymentHistoryTable .table.align-items-center th {
  vertical-align: middle;
}
.PaymentHistoryTable .table-flush td, .PaymentHistoryTable .table-flush th {
  border-right: 0;
  border-left: 0;
}
.PaymentHistoryTable .table td,.PaymentHistoryTable .table th {
  font-size: .8125rem;
  white-space: nowrap;
}
.PaymentHistoryTable .table td, .PaymentHistoryTable .table th {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.PaymentHistoryTable .badge-dot {
  font-size: .875rem;
  font-weight: 400;
  padding-right: 0;
  padding-left: 0;
  text-transform: none;
  background: 0 0;
}

.PaymentHistoryTable .badge {
  text-transform: uppercase;
}
.PaymentHistoryTable .mr-4, .mx-4 {
  margin-right: 1.5rem!important;
}
.PaymentHistoryTable .badge {
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  padding: .35rem .375rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: .375rem;
}

.PaymentHistoryTable .badge-dot i {
  display: inline-block;
  width: .375rem;
  height: .375rem;
  margin-right: .375rem;
  vertical-align: middle;
  border-radius: 50%;
}

.PaymentHistoryTable .bg-warning {
  background-color: #fb6340!important;
}


.PaymentHistoryTable-status{
  font-size: 12px;
  padding-left: 5px;
}

.PaymentHistoryTable{
  margin-top: 50px;
}



.bg-danger-light{
  background-color: #dc35451f;
}
.bg-success-light{
  background-color: #28a7451c;
}
.bg-warning-light{
  background-color: #fb634024;
}