.courses-main-div {
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  height: 100vh;
  overflow-x: hidden;
  background-repeat: no-repeat !important;
  background-size: cover;
 
 
}
.box-mega-container{
padding: 40px 0px;
}
.sec-head{
text-align: center;
color: #192a56;
margin-bottom: 2vw;
}

.card{
    width: 100%;
    border-radius: 3px;
    max-height: 300px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    padding: 14px 35px 14px 35px;
}

.card:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card h3{
  font-weight: 700;
  color: #192a56;
  font-size: 1.4em;
  text-align: center;
}

.card img{
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.card p{
  text-align: center;
}

@media(min-width: 990px){
  .card{
   max-height: 400px;
  }
} 
@media(min-width: 500px){
  .card{
   max-height: auto;
  }
} 

@media (min-width: 320px) and (max-width: 1200px) {
  .cancel-reg {
    font-size: 0.7rem !important;
  }
  .already-reg {
    font-size: 0.7rem !important;
  }
  .open-portal {
    font-size: 0.7rem !important;

  }
}



.cancel-reg-btn {
  margin-top: 5px;
  max-width: 200px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  border: solid 1px var(--green-blue);
  color: white;
  cursor: not-allowed;
  display: block;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-color: red;
}


.already-applied-btn {
  margin-top: 5px;
  max-width: 200px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  border: solid 1px var(--green-blue);
  color: white;
  cursor: not-allowed;
  display: block;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-color: #444444;
}

.courses-submit-button {
  margin-top: 5px;
  max-width: 200px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  border: solid 1px var(--green-blue);
  color: #00CE9F;
  border: 1px solid #00CE9F;
  display: block;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-color: white;
}

.courses-submit-button:hover {
  transition: 0.5s;
  color: white;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3)
}


.already-reg{
  margin-top: 5px;
  max-width: 250px;
  width: 80%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  color: white;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.already-reg:hover { 

  transition: 0.5s;
  color: #00CE9F;
  background-color: white;
  border: solid 1px #00CE9F;
  background-image: linear-gradient(to bottom, white)
}

.cancel-reg{
  margin-top: 5px;
  max-width: 250px;
  width: 80%;
  height: auto;
  padding: 10px;
  transition: 0.5s;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border-radius: 100px;
  border: solid 1px var(--green-blue);
  color: #00CE9F;
  border: 1px solid #00CE9F;
  display: block;
  cursor: pointer !important;
  outline-style: none !important;
  background-color: white;
}

.cancel-reg:hover { 
  transition: 0.5s;
  color: white;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3)
}

.courses-submit-button {
  margin-top: 5px;
  max-width: 200px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  border: solid 1px var(--green-blue);
  color: #00CE9F;
  border: 1px solid #00CE9F;
  display: block;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-color: white;
}

.courses-submit-button:hover {
  transition: 0.5s;
  color: white;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3)
}


.open-portal{
  margin-top: 5px;
  max-width: 250px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  color: white;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border: none;
}

.open-portal:hover { 
  transition: 0.5s;
  color: #00CE9F;
  background-color: white;
  border: solid 1px #00CE9F;
  background-image: linear-gradient(to bottom, white)
}


.Form_radio-label{
  display: inline-block;
}
.Form_radioLabel{
color: #017969;
cursor: pointer;
}
.Form_radio-group{
  display: inline-block;
  margin-left:5%;
}

.Form_Ellipse-13 {
  width: 16px;
  height: 16px;
  position: relative; 
  top: 3px;
  left: 5px;
}

.sec-sub-head{
  color: #192a56;
  font-size: 2em;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  justify-items: center;
  margin-left: 15px;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 40px;
}

.course-initial{
  text-transform: uppercase;
  color: #626e8c;
  font-size: 0.7em;
}


.platform-label { 
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  color: #017969;
}

.alert-toast {
   padding: 10px;
   background-color: #f3565f;
   color: white;
   box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
}

.view-admit-card {
  color: #00645f;
  font-weight: bold;
  border: 1px solid #00645f;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.view-admit-card:hover{
  color: white;
  background-color: #00645f;
  border: 1px solid black;
}

.edit-doc-link {
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.edit-doc-link:hover{
  color: black;
  transition: 0.5s;
}

.catalog-announcements {
  color: #00645f;
  font-weight: bold;
  font-size: larger;
  cursor: pointer;
}
.catalog-announcements:hover {
  background: linear-gradient(80deg,transparent,rgba(14, 181, 164, 0.16),transparent);
}
.catalog-announcements:active {
  background: linear-gradient(80deg,transparent,rgba(14, 181, 164, 0.16),transparent);
}
