.card-voucher {
  background: white;
  border-radius: 5px;
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 1px 20px 0 rgba(0,0,0,.1);
}


.card-voucher-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-voucher .card-voucher-header .card-voucher-title {
  color: #2c2c2c;
  font-weight: 100;
}
.card-voucher-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.card-voucher-title {
  margin-bottom: .75rem;
}

.card-voucher-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.col-form-voucher-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.form-voucher-group {
  margin-bottom: 1rem;
}
.form-voucher-control[disabled], .form-voucher-control[readonly], fieldset[disabled] .form-voucher-control {
  background-color: #f7f7f7;
    color: #00000045;
  cursor: not-allowed;
}
.form-voucher-group .form-voucher-control-static, .input-group .form-voucher-control-static {
  margin-top: 9px;
}
.card-voucher h1, .card-voucher h2, .card-voucher h3, .card-voucher h4, .card-voucher h5, .card-voucher h6, .card-voucher p, h1, h2, h3, h4, h5, h6 {
  color: hsla(0,0%,100%,.8);
}
.form-voucher-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .5rem .7rem;
  font-size: .875rem;
  line-height: 1.42857;
  color: #474747;
  background-color: initial;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: .25rem;
  box-shadow: none;
  -webkit-transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
  transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
}
.form-voucher-text {
  display: block;
  margin-top: .25rem;
}

.form-voucher-horizontal .col-form-voucher-label, .form-voucher-horizontal .label-on-right {
  padding: 10px 5px 0 15px;
  text-align: right;
  max-width: 180px;
}

@media (max-width: 680px){
  .form-voucher-horizontal .col-form-voucher-label, .form-voucher-horizontal .label-on-right {
    padding: 10px 5px 0 15px;
    text-align: left;
    max-width: 180px;
  }
}

.card-voucher label {
  font-size: .75rem;
  margin-bottom: 5px;
}
.card-voucher label {
  color: #2c2c2c;
}
.col-form-voucher-label {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.form-voucher-text {
  font-size: .75rem;
  color: hsla(0,0%,100%,.8);
}
.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  line-height: 18px;
  margin-bottom: 0;
  -webkit-transition: color .3s linear;
  transition: color .3s linear;
}
.form-check-inline{
  margin-top: 10px;
    margin-bottom: 15px;
}
.form-voucher-horizontal .checkbox-radios .form-check:first-child {
  margin-top: 8px;
}

.form-check .form-check-sign:before {
  /* content: " "; */
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  cursor: pointer;
  /* border-radius: 3px; */
  /* top: 0; */
  border: 1px solid #818181;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
}

.form-check input[type=checkbox]+.form-check-sign:after {
  opacity: 0;
  font-size: 10px;
  margin-top: 0;
}
.form-check {
  font-family: nucleo;
  content: "\EA1B";
  top: 0;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  /* color: #fff; */
  font-weight: 600;
  border: 0;
  background-color: inherit;
}

/* .form-check-radio input[type=radio]:checked+.form-check-sign:before {
  border-color: #00c995;
} */
 .form-check-radio .form-check-sign:before {
  /* content: " "; */
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #818181;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
  padding: 1px;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
  color: transparent;
  margin-top: -3px;
}

.form-check-radio input[type=radio]:checked+.form-check-sign:after {
  opacity: 1;
  color: transparent;
}

.form-check-radio input[type=radio]:checked+.form-check-sign:after {
  width: 6px;
  height: 6px;
  background-color: #00d78c;
  border-color: #00d78c;
  top: 9px;
  left: 6px;
}
.form-check-radio input[type=radio], .form-check-radio input[type=radio]+.form-check-sign:after {
  opacity: 0;
}

.form-check input[type=checkbox]:checked+.form-check-sign:after {
  opacity: 1;
  font-size: 10px;
  margin-top: 0;
}
.form-check input[type=checkbox]+.form-check-sign:after {
  opacity: 0;
  font-size: 10px;
  margin-top: 0;
}


.form-check input[type=checkbox]:checked+.form-check-sign:before {
  border: none;
  background-color: #e14eca;
}
.form-check input[type=checkbox], .radio input[type=radio] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.form-voucher-horizontal .form-voucher-check-inline {
  margin-top: 6px;
}

.dropdownInlineStyle1{
  display: inline-block;
}
.dropdownInlineStyle2{
  box-sizing: content-box; width: 2px; background: 0px center; border: 0px; font-size: inherit; opacity: 1; outline: 0px; padding: 0px; color: inherit;
}

.dropdownInlineStyle3{
  position: absolute; top: 0px; left: 0px; visibility: hidden; height: 0px; overflow: scroll; white-space: pre; font-size: 14px; font-family: Poppins, sans-serif; font-weight: 400; font-style: normal; letter-spacing: normal; text-transform: none;
}

.dropdownInlineStyle4{
  display: inline-block;
}
.dropdownInlineStyle5{
  box-sizing: content-box; width: 2px; background: 0px center; border: 0px; font-size: inherit; opacity: 1; outline: 0px; padding: 0px; color: inherit;
}

.btn-voucher{
 
  /* background-image: linear-gradient(to bottom left,#e14eca,#ba54f5,#e14eca); */
  background-size: 210% 210%;
  background-position: 100% 0;
  /* background-color: #e14eca; */
  /* transition: all .15s ease; */
  background-image: linear-gradient(270deg,#08d88d,#0eb5a3);
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  /* -webkit-user-select: none; */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 11px 40px;
  font-size: .875rem;
  line-height: 1.35em;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-voucher:disabled,
.btn-voucher[disabled]{
  background: #00a76ca6;
  cursor: not-allowed;
}



/* new data css/ */

.theme--light.v-card {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,.87);
}
.v-card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  border-radius: 3px;
}
.v-card {
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  display: block;
  min-width: 0;
  position: relative;
  text-decoration: none;
  -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
  transition: .3s cubic-bezier(.25,.8,.5,1);
}

.v-card>:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-card .v-offset {
  top: -20px!important;
  margin-bottom: -20px!important;
}
.v-offset {
  margin: 0 auto;
  max-width: calc(100% - 32px);
  position: relative;
}


.v-card .v-offset .v-card--material__header.v-card {
  padding: 15px;
}
.v-card.orange {
  background: linear-gradient(270deg,#08d88d,rgba(14,181,164,.8))!important;
  box-shadow: 0px 0px 27px -9px #00645f !important;

}
.v-card--material__header.v-card {
  border-radius: 4px;
}
.theme--dark.v-card {
  background-color: #424242;
  border-color: #424242;
  color: #fff;
}
.v-card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  border-radius: 3px;
}
.v-card {
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  display: block;
  min-width: 0;
  position: relative;
  text-decoration: none;
  -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.elevation-10 {
  -webkit-box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
  box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
}
.orange {
  background-color: #ff9800!important;
  border-color: #ff9800!important;
}

.v-card>:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-card>:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.v-card .title {
  margin-top: 0;
  line-height: 1.5em!important;
  letter-spacing: 0!important;
  font-size: 1.125rem!important;
  margin-bottom: 5px!important;
}
.font-weight-light {
  font-weight: 300!important;
}
.mb-2 {
  margin-bottom: 8px!important;
}
.title {
  font-family: Roboto,sans-serif!important;
  font-size: 20px!important;
  font-weight: 500;
  letter-spacing: .02em!important;
  line-height: 1!important;
}

.v-card .v-offset .category {
  color: hsla(0,0%,100%,.62);
}
.v-card .category {
  margin: 0;
}
.category {
  font-size: 14px;
}
.font-weight-thin {
  font-weight: 100!important;
}

.v-card>:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-card__text {
  padding: 16px;
  width: 100%;
}

.voucher-form-loading{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #4d4d4d1a;
}


.errorMessageTimeslot{
  color: red;
    padding-top: 10px;
}


.remainSeatStatusTrue{
  color: #00645f;
}

.remainSeatStatusFalse{
  color: red;
}


.voucherFormAlert{
  margin: auto;
  padding-top: 10px;
  color: red;
  list-style-type: none;
}