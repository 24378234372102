.cardBody2{
    width: 570px;
    height: 340px;
    padding: 0px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 3px solid #00ce9f;
    border-bottom: 3px solid #00ce9f;
    min-width: 570px;
    max-width: 570px;
}
.status{
    text-align: center;
    margin-top: 30px;
    margin-bottom: -12px;
    margin-left: 50px;
    margin-right: 50px;
    color: red;
    font-weight: 400;
    font-size: 1.2em
}
.statusRed{
    text-align: center;
    margin-top: 30px;
    margin-bottom: -12px;
    margin-left: 50px;
    margin-right: 50px;
    color: red;
    font-weight: 400;
    font-size: 1.2em
}
.cardBody2 h1{
    display: inline-block;
    font-size: 24px;
    margin-left: 60%;
    color: #017969;
    font-weight: 900;
    margin-top: 5px;
}
.id-card-2-h1{
    margin-left: 15% !important;
}
.logoImage{
    height: 84px;
    margin: 5%;
}
.idCardLocation a{
    color: black;
    text-decoration: underline !important;
}
.logoForm b{
    color: #017969;
}
.logoForm{
    min-width: 500px;
    
}

.logoForm p{
    line-height: 10px;
    color: black;
    font-size: 1rem;
    font-weight: bolder;
}

.cardImage{
    margin: 5%;
    margin-left: 10%;
    border: 1px solid #00ce9f;
}


.UserAuthCard b{
    color: #017969;
}
.UserAuthCard{
    margin-top: 15%;
}
.UserAuthCard p{
    color: black;
    font-weight: bolder;
    font-size: 1.1rem;
    margin-top: 67%;
    width: 130%;
    padding-top: 4px;
    border-top: 1px solid;
    margin-left: -35%;
    padding-left: 18%;
}


.vertical-card{
    width: 340px;
    height: 570px;
  
    padding: 0px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 3px solid #00ce9f;
    border-right: 3px solid #00ce9f;
    min-width: 340px;
    max-height: 570px;
  }
  .vertical-card-bg{
    width: 568px;
    height: 334px;
    background-image: url(/static/media/LogoPIAIC.1624cca8.svg);
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200px;
    opacity: 0.1;
    transform: rotate(90deg);
    margin: 118px 0 0 -118px;
  }
  .cardBackBodyFlex{
    display: flex;
    /* flex-direction: column; */
    height: 100%;
  }
  .cardBackBodyFlex-box{
    flex: 1;
  }

  .qHeadingBackCard{
    font-size: 150px;
    color: #00cf9e;
    font-weight: 900;
    /* transform: rotate(90deg); */
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .qrCodeCont{
      transform: rotate(90deg)
  } */

  .card2{
      margin-top: 6px;
      transform: rotate(180deg);
  }


#Voucher-batch b {
    font-size: 1.5rem !important;
    font-weight: bold;
}

#Voucher-batch span {
    color: black;
}


.bringColorCopyText{
    color: red;
    padding-top: 50px;
    font-size: 30px;
    text-align: center;
} 
.voucherInstruction{
    color: red;
    padding-top: 0px;
    font-size: 20px;
    text-align: center;
} 