.adminSignup-main-div {
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  width: 100%;
  height: 100vh;
  display: block;
}

.adminSignup-form-div {
  max-width: 552px;
  width: 100%;
  min-height: auto;
  border-radius: 5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 0px auto 0px auto;
  padding-bottom: 20px;
}

.adminSignup-head {
  max-width: 552.2px;
  width: 100%;
  height: 66.3px;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0px 0px;
}

.adminSignup-head-text {
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
}

.adminSignup-m-r-l {
  margin: 0px auto 0px auto;
  
}

.adminSignup-display-block {
  display: block;
}

.adminSignup-logo {
  display: block;
  margin-top: 25px;
}
.adminSignup-piaic-text {
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #017969;
  margin-top: 20px;
}
.adminSignup-form {
  padding: 0px 30px 0px 30px;
}

.adminSignup-submit-button {
  margin-top: 27px;
  max-width: 200px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  border: solid 1px var(--green-blue);
  color: #00CE9F;
  border: 1px solid #00CE9F;
  display: block;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;
  outline-style: none !important;
  background-color: white;
}

.adminSignup-form input[type="email"], .adminSignup-form input[type="password"], .adminSignup-form input[type="text"] {
  padding: 25px 10px !important;
  font-size: 16px;
}

.adminSignup-form-div img {
  height: 120px;
}


.adminSignup-submit-button:hover {
  transition: 0.5s;
  color: white;
  background-image: linear-gradient(to left, #08d88d, #0eb5a3)
}


.adminSignup-text-light-green {
color: #00CE9F;
}


@media only screen and (min-width:320px) and (max-width: 1370px) {
.adminSignup-form-div, .adminSignup-main-div {
height: 100%;
}
}

.swal-back > .swal-text {
  color: red;
  text-align: center;
  line-height: 1.7;
}
/* @media only screen and (min-width:760px) and (max-width: 1024px) {
  .adminSignup-form-div, .adminSignup-main-div {
  height: 100vh;
  }
  } */