.errorPlaceholderCont{
  width: 200px;
}

.errorMessagePlaceholder{
  text-align: center;
  font-size: 20px;
  color: #a7a7a7;
  max-width: 300px;
}

.errorMessagePlaceholderImg{
  width: 200px;
}

.errorMessageReload{
  text-align: center;
  font-size: 20px;
  color: #00645f;
  max-width: 300px;
  text-decoration: underline;
  cursor: pointer;
}

.errorMessageReload:hover{
  color: #00645fc9;
}