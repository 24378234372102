.Voucher-body{
  margin-top: 20px;
  margin-bottom: 20px;
  width: 450px;
  border: 1px solid #00000036;
  font-family: myfontRegular;
}

.Voucher-cont{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.voucher-logo-img{
  height: 100px;
  margin: 0 5px 0 5px;
  padding: 8px;
}
.payment-logo-img{
  height: 35px;
  margin: 0 5px 0 5px;
  padding: 8px;
}
/* .Voucher-body:hover{
  -moz-transform: scale(1.7) rotate(360deg) translate(50px, 50px) skew(55deg, 67deg);
  -webkit-transform: scale(1.7) rotate(360deg) translate(50px, 50px) skew(55deg, 67deg);
  -o-transform: scale(1.7) rotate(360deg) translate(50px, 50px) skew(55deg, 67deg);
  -ms-transform: scale(1.7) rotate(360deg) translate(50px, 50px) skew(55deg, 67deg);
  transform: scale(1.7) rotate(360deg) translate(50px, 50px) skew(55deg, 67deg);
  -webkit-box-shadow: 5px 5px 50px 5px #000000; 
box-shadow: 5px 5px 50px 5px #000000;
-webkit-box-shadow: 5px 5px 0px 0px #289FED, 10px 10px 0px 0px #5FB8FF, 15px 15px 0px 0px #A1D8FF, 20px 20px 0px 0px #CAE6FF, 25px 25px 0px 0px #E1EEFF, -50px -50px 50px -30px rgba(0,0,0,0); 
box-shadow: 5px 5px 0px 0px #289FED, 10px 10px 0px 0px #5FB8FF, 15px 15px 0px 0px #A1D8FF, 20px 20px 0px 0px #CAE6FF, 25px 25px 0px 0px #E1EEFF, -50px -50px 50px -30px rgba(0,0,0,0);
} */
.Voucher-body b{
  font-family: myfontBold;
}
.logoImage2 {
  height: 130px;
  margin: 10px 60px -10px 10px;
}
.Voucher-body p{
  padding: 6px 1%;
  font-size: 13px;
  height: 100%;
  border-bottom: 1px solid #0000001f;
  width: 98%;
  border-radius: 2px;
  margin: 0px;
  text-align: left;
}
.Voucher-body h4{
  color: black;
  font-size: 16px;
  padding: 10px 0;
  font-family: myfontBold;
  margin: 0;
}
.cardBody2-bg{
    width: 568px;
    height: 334px;
    background-image: url(../../../assets/LogoPIAIC.svg);
    /* width: 100%; */
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200px;
    opacity: 0.1;
}

.Voucher-body h5{
  color: black;
  font-size: 14px;
  font-family: myfontBold;
  margin: 0;
}
.Voucher-body h6{
    color: #0000007d;
    font-size: 12px;
    font-family: myfontBold;
    margin: 0;
}
.Voucher-body table{
  padding: 1%;
}
.Voucher-body .d-flex{
  width: 99%;
}
.voucher-printBtn{
  border: none;
  outline-style: none;
  margin-top: -7px;
  color: #fff;
  padding: 11px 44px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(right,#08d88d,#0eb5a3);
  background-image: linear-gradient(270deg,#08d88d,#0eb5a3);
  margin: 0 10px 0 10px;
}
.voucher-cancelBtn{
  outline-style: none;
  margin-top: -7px;
  color: #b900007e;
  font-weight: 400;
  padding: 9px 42px;
  font-size: 12px;
  background-color: #f4f3ef;
  border-radius: 5px;
  font-weight: bold;
  background-image: none;
  margin: 0 10px 0 10px;
  border: 2px solid #b900007e;
}

.voucher-cancelBtn:hover{
  cursor: pointer;
  border: none;
  outline-style: none;
  margin-top: -7px;
  color: #fff;
  padding: 11px 44px;
  font-size: 12px;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(right,#d80808d6,#b50e0e51);
  background-image: linear-gradient(270deg,#d80808d6,#b50e0e51);
  margin: 0 10px 0 10px;
}
.voucher-printBtn:hover{
  cursor: pointer;
  outline-style: none;
  margin-top: -7px;
  color: #00b9a0;
  font-weight: 400;
  padding: 9px 42px;
  font-size: 12px;
  background-color: #f4f3ef;
  border-radius: 5px;
  background-image: none;
  margin: 0 10px 0 10px;
  border: 2px solid #00b9a0;
}

.payment-d-flex-flexbox1-1 {
  flex: 1;
} 
.payment-d-flex-flexbox1-2 {
  flex: 5;
}

.Voucher-d-flex-flexbox1-1, .Voucher-d-flex-flexbox1-2,
.Voucher-d-flex-flexbox2-1, .Voucher-d-flex-flexbox2-2, 
.Voucher-d-flex-flexbox3-1, .Voucher-d-flex-flexbox3-2,
.Voucher-d-flex-flexbox4-1, .Voucher-d-flex-flexbox4-2
{
  flex: 1;
}
.Voucher-d-flex-flexbox1-1 p, 
.Voucher-d-flex-flexbox2-1 p, 
.Voucher-d-flex-flexbox3-1 p,
.Voucher-d-flex-flexbox4-1 p
{
  width: 98%;
  margin-left: 2%;
}
.Voucher-d-flex-flexbox1-2 p, 
.Voucher-d-flex-flexbox2-2 p, 
.Voucher-d-flex-flexbox3-2 p,
.Voucher-d-flex-flexbox4-2 p
{
  width: 98%;
  margin-right: 2%;
}
.Voucher-sign-section{
padding-top: 60px;
}

.Voucher-d-flex-flexbox2-1 p, .Voucher-d-flex-flexbox2-2 p,
.Voucher-d-flex-flexbox3-1 p, .Voucher-d-flex-flexbox3-2 p,
.Voucher-d-flex-flexbox4-1 p, .Voucher-d-flex-flexbox4-2 p
{
  border: none;
}
.Voucher-d-flex-flexbox3-1 p, .Voucher-d-flex-flexbox3-2 p
{
      border-top: 2px solid black;
      width: fit-content;
}

.Voucher-d-flex-flexbox3-1 p{
  float: left;
}

.Voucher-d-flex-flexbox3-2 p{
  float: right;
}


.Voucher-d-flex-checkbox-style
{
  margin-bottom: -2%;
  border: 1px solid black;
  width: 26px;
  height: 18px;
  display: inline-block;
  margin-left: 1%;
}


@media print {
  body {
    -webkit-print-color-adjust: exact;
    background: white !important;
  }
  #printButton, .voucher-cancelBtn {
    display: none;
  }
.voucher-cont{
   margin-top: 20px;
}

.card1{
    margin-top: 400px;  
}
  @page {
    margin: 0;
    margin-top: 40px; 
  }
  body {
    margin: 0cm;
  }
  #pdfText {
    margin-top: 10%;
    font-size: 22px;
  }
  .Voucher-cont{
    padding: 50px;
    /* margin-top: -50px; */
  }
.hide-on-print{
  display: none;
}
  .copyright-cont {
    position: fixed;
    bottom: 0;
  }
}