#myForm {
    padding: 5% 0;
  }
  
.Email-verification-text {
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 0%;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
}



    .btn-outline-primary { 
        border: solid 2px #00645f82 !important;
        color: #017969;
        height: 50px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-weight: bold;
        font-size: 0.6em;
    }

    .btn-outline-primary:hover { 
        background-color: cadetblue !important;
    }

  .label {
    font-size: 40px;
    margin: 3% 0;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
  }
  
  .ContactClass {
    width:100%;
    height: 100vh;
    background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
  .main{
    display: flex;
    justify-content: center;
  }
