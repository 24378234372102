.click-to-download-heading{
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
  color: #00645f;
  text-decoration: underline;
  padding: 20px 10px 0 10px;
  cursor: pointer;
}

.click-to-download-heading a{
  color: #00645f;
}
.click-to-download-heading a:hover{
  color: #00a59c;
}

.react-sanfona{
  margin: 20px 0;
}

.react-sanfona-item{
  background-color: #e2e1de;
  color: #00645f; 
}


.react-sanfona-item-expanded{
  background-color: #777777;
  color: white;
}

.react-sanfona-item-body{
  background-color: #eae9e5;
  color: #28292b;
  /* padding: 15px; */
}

.react-sanfona-item-title{
  font-size: 24px;
  padding: 8px 20px;
  background-color: #777777;
  color: white;
}

.react-sanfona-item-title:hover{
  
  background-color: #999595;
}

.accordionItemExamItemBody{
  padding: 15px;
}


.examsTopicContainer{
  background-color: gainsboro;
  padding: 10px;
  margin: 10px 0;
  overflow: hidden;
}

.examsTopicContainer h2{
  font-size: 24px;
  font-weight: 400;
  color:#28292b;
}

.examsTopicContainer a{
  font-size: 14px;
  color: #00645f;
}

.examsTopicContainer p{
  font-size: 16px;
}


.topicExamHead{
  font-size: 24px;
    border-top: 1px solid gainsboro;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 5px;
    color: #00645f;
    font-weight: 900;
}

.version-heading{
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
  color: #00645f;
  padding: 0px 10px 20px 10px;
}