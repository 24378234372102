.paymentBranches-branches-section{
  padding-top: 50px;
  padding-left: 60px;
}

@media (max-width: 500px){
  .paymentBranches-branches-section{
    padding-left: 40px;
  }
}

.paymentBranches-branches-cont{
  width: 95%;
  height: auto;
  min-height: 100px;
  /* padding-top: 50px; */
  /* padding-bottom: 50px;  */
  /* border: 1px solid black; */
  margin: auto;
  border-radius: 2px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    background-color: #fff;
    border-color: #fff;
    color: rgba(0,0,0,.87);
}

.paymentBranches-branches-names-cont{
  width: 90%;
  /* height: auto; */
  /* padding-top: 50px; */
  min-height: 90px;
  /* padding-top: 120px;   */
  /* border: 1px solid black; */
  margin: auto;
  margin-top: -40px;
  border-radius: 4px;
  background: linear-gradient(270deg,#08d88d,rgba(14,181,164,.8));
  box-shadow: 0px 0px 27px -9px #00645f;
  position: relative;
  z-index: 5;
}

.paymentBranches-branches-names{
  width: 100%;
  min-height: 90px;
  /* padding-bottom: 50px; */
  margin: auto;
  /* margin-top: -40px; */
  background-color: white;
  border-radius: 4px;
}

.paymentBranches-branches-cont-table{
  margin-bottom: 0px !important;
}

.paymentBranches-branches-names-table-tr:hover{
  background-color: #2bcf9f14;
  /* box-shadow: 0px 0px 30px -9px black; */
  cursor: pointer;
}

.paymentBranches-branches-names-table-tr-selected{
  /* background-color: #2bcf9f14; */
  box-shadow: 0px 0px 30px -9px black;
  background: linear-gradient(270deg,#08d88d,rgba(14,181,164,.8));
  color: white;
  /* cursor: pointer; */
}
.paymentBranches-branches-names-cont-heading h1{
  text-align: center;
  color: white;
  font-size: 50px;
  font-variant: small-caps;
  font-weight: 900;
  padding: 5px;
}


.paymentBranches-branches-cont-iframesize{
  width: 100%;
  height: 400px;
  margin-bottom: -7px;
}

.paymentBranches-branches-names-cont-iframe-placeholder h1{
  text-align: center;
    color: #01645f;
    font-size: 30px;
    /* -webkit-font-feature-settings: "c2sc"; */
    /* font-feature-settings: "c2sc"; */
    /* font-variant: small-caps; */
    /* font-weight: 900; */
    padding: 5px;
    padding-top: 15px;
}

@media (max-width: 600px)
{
  .paymentBranches-branches-names-cont-heading h1{
    font-size: 6vw;
  }
  .paymentBranches-branches-names-cont-iframe-placeholder h1{
    font-size: 5vw;
  }
}
