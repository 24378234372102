.course-lessons{
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 111113;
  top: 60px;
  bottom: 0;
  /* overflow: scroll; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.listItemExpandableLessonsH3{
  color: #ffffffb5;
  font-size: 1rem;
  margin: 0;
}

.ExpandIcon{
  height: 13px;
}

.course-lessons-sidebar-list{
  padding: 0 0;    
  line-height: 1.33333em;
  margin: 0;
  list-style-type: none;
}

.course-lessons-sidebar-heading-cont h1{
  font-size: 1.5rem;
  padding: 5px 0px 10px 0px;
  color: gray;
  margin: 0;
  padding-top: 10px;
}
.course-lessons-sidebar-heading-cont h2{
  font-size: 1.2rem;
  padding: 0px 0px 0px 0px;
  color: gray;
  margin: 0;
}

.goBackButton-courses{
  background-color: #80808036;
  width: calc(100% + 30px);
  font-size: 20px;
  padding: 10px;
  color: #ffffffa3;
  /* margin: 0 30px 0 30px; */
  margin-left: -15px;
  font-weight: 600;
}
.goBackButton-courses:hover{
  cursor: pointer;
  background-color: #171717;
}
.goBackButton-courses img{
  height: 20px;
  margin-top: -5px;
}