.AdminLogin-main-div {
    background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
    width: 100%;
    height: 100vh;
    display: block;
}

.AdminLogin-form-div {
    max-width: 552px;
    width: 100%;
    min-height: auto;
    border-radius: 5px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin: 0px auto 0px auto;
    padding-bottom: 20px;
}

.AdminLogin-head {
    max-width: 552.2px;
    width: 100%;
    height: 66.3px;
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px 5px 0px 0px;
}

.AdminLogin-head-text {
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
}

.AdminLogin-m-r-l {
    margin: 0px auto 0px auto;
    
}

.AdminLogin-display-block {
    display: block;
}

.AdminLogin-logo {
    display: block;
    margin-top: 25px;
}
.AdminLogin-piaic-text {
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
    margin-top: 20px;
}
.AdminLogin-form {
    padding: 0px 30px 0px 30px;
}

.AdminLogin-submit-button {
    margin-top: 27px;
    max-width: 200px;
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 100px;
    border: solid 1px var(--green-blue);
    color: #00CE9F;
    border: 1px solid #00CE9F;
    display: block;
    cursor: pointer;
    transition: 0.5s;
    font-weight: bold;
    outline-style: none !important;
    background-color: white;
}

.AdminLogin-form input[type="email"], .AdminLogin-form input[type="password"], .AdminLogin-form input[type="text"] {
    padding: 25px 10px !important;
    font-size: 16px;
}

.AdminLogin-form-div img {
    height: 120px;
}


.AdminLogin-submit-button:hover {
    transition: 0.5s;
    color: white;
    background-image: linear-gradient(to left, #08d88d, #0eb5a3)
}


.AdminLogin-text-light-green {
color: #00CE9F;
}


@media (max-width: 576px) {
.AdminLogin-form-div {
height: 100%;
}
}

.forget-pass{
    color: #00645f;
    padding: 10px 0px 0px 3px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-top: -8px;
    width: auto;
    float: right;
}
.forget-pass:hover{
    color: #03d2a5;
}
.sign-up{
    color: #00645f;
    display: flex;
    justify-content: flex-start;
}
.sign-up-link{
    color: #00645f;
    text-decoration: underline;
    /* margin-left: 3px; */

}
.sign-up-link:hover{
    color: #03d2a5;
}


.alert-message-modal-forgetpass{
    color: #e40000b3;
    font-size: 16px;
    padding-right: 45px;
    padding-left: 5px;
    padding-top: 5px;
}
.forget-password input[type='email']:focus{
    border: none;
    outline: none;
    outline-style: none;
}

.forget-password input[type='email']{
    display: block;
    width: 100%;
    padding: 3px 0 13px 10px;
    border: none;
    outline: none;
    outline-style: none;
}
.modal-forgetpass-btn{
    width: 90vw;
    height: 60px;
    min-width: 270px;
    max-width: 500px;
    font-weight: 900;
    border: none;
    outline-style: none;
    background-color: #00645f;
    color: white;
    padding: 0 15px 0 15px;
}
.bodal-light-btn{
    background-color: #00645f82 !important;
}
.modal-forgetpass-btn:hover{
    background-image: linear-gradient(to left, #08d88d52, #0eb5a340);
}
.check-email-pass{
    font-size: 1.5rem;
    color: #00CE9F;
    font-variant: small-caps;
    font-weight: 900;
    padding: 45px;
}

.forget-password h2{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 100;
    color: #00645f;
    padding: 5% 0 5% 0;
    border-bottom: 1px solid #0000000f;
}

.ReactModal__Content{
   
    border: none !important;
    overflow: hidden !important;
    padding: 0px !important;
    /* border-radius: 20px !important; */
    box-shadow: 0px 0px 35px -12px black;
}

.ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.28) !important;
}

.successMessage{
    color: #00CE9F;
}

.crossBtnModal{
    color: gray;
    text-align: center;
    font-size: 40px;
    margin-top: -10px;
    padding-right: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    cursor: pointer;
}
.crossBtnModal:hover{
    color: black;
}



.AdminLogin-resend-span { 
    cursor: pointer; 
    color: #017969 ;
    font-weight: bold;
    height: auto; 
    width: auto; 
    border-radius: 20px;
    text-decoration: underline; 
}

.AdminLogin-resend-span:hover { 
    text-decoration: none; 
}




