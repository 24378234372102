body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
}
@font-face{
  font-family: myfontBold;
  src: url("./assets/Fonts/open-sans/OpenSans-Bold.ttf");
}
@font-face{
  font-family: myfontExtraBold;
  src: url("./assets/Fonts/open-sans/OpenSans-ExtraBold.ttf");
}
@font-face{
  font-family: myfontItalic;
  src: url("./assets/Fonts/open-sans/OpenSans-Italic.ttf");
}
@font-face{
  font-family: myfontLight;
  src: url("./assets/Fonts/open-sans/OpenSans-Light.ttf");
}
@font-face{
  font-family: myfontRegular;
  src: url("./assets/Fonts/open-sans/OpenSans-Regular.ttf");
}
*{
  font-family: "myfontRegular",sans-serif;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svgColor{
  color: white;
}

.bg-red{
  background-color: red;
}
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3a3a3a2a; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(52, 133, 124); 
}

.swal-button {
  background-color: #00645f !important;
}