.Announcements{
    margin: 10px;
}
.Announcement-heading{
    font-size: 4vw;
    text-align: center;
    color: #01645f;
    font-weight: 900;
    font-variant: petite-caps;
}

.no-Announcement{
  font-size: 2vw;
  font-weight: 900;
  color: #01645f;
  font-weight: 500;
  font-variant: petite-caps;
}
.timeline-cont {
    width: 100%;
    margin: -40px auto;
    padding: 10px;
    font-size: 100%;
    color: #444444;
  }
 
  .bck {
    color: #444444;
    margin-top: 20px;

  }
  
  .hero-heading {
   font-size: 400%;
   text-transform: uppercase;
   font-weight: 400;
 
 }
 
 .hero-subtitle { 
   color: rgb(224, 224, 224);
 }
 
 header {
     background: #008a7e;
     color: #fff;
     padding: 150px 0;
 }
 
 header p {
     color: rgb(182, 178, 178);
     font-size: 25px;
 }
 
  
  /* -------------------------------------
   * timeline
   * ------------------------------------- */
  #timeline {
    list-style: none;
    margin: 50px 0 30px 120px;
    padding-left: 30px;
    border-left: 6px solid #008a7e;
  }
  #timeline li {
    margin: 40px 0;
    position: relative;
  }
  #timeline p {
    margin: 0 0 15px;
  }
  
  .timeline-cont .btn { 
    border: solid 3px #008a7e;
    border-radius: 6px;
    background-color: #008a7e;
    color: white !important; 
  }
 
  .date {
    margin-top: -10px;
    top: 50%;
    left: -175px;
    font-size: 0.95em;
    line-height: 20px;
    position: absolute;
  }
  
  .circle-ci {
    margin-top: -10px;
    top: 50%;
    left: -44px;
    width: 20px;
    height: 20px;
    background: #008a7e;
    border: 5px solid rgb(68, 68, 68);
    border-radius: 50%;
    display: block;
    position: absolute;
  }
  
  .content {
    max-height: 20px;
    padding: 50px 20px 0;
    border-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-radius: 0.5em;
    position: relative;
  }
  .content:before, .content:after {
    content: "";
    width: 0;
    height: 0;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
    right: 100%;
  }
  .content:before {
    border-right-color: inherit;
    border-width: 20px;
    top: 50%;
    margin-top: -20px;
  }
  .content:after {
    border-right-color: #48b379;
    border-width: 17px;
    top: 50%;
    margin-top: -17px;
  }
  .content  {
    max-height: 0;
    color: transparent;
    text-align: justify;
    word-break: break-word;
    hyphens: auto;
    overflow: hidden;
  }
  
 .head {
    font-size: 1.3em;
    position: absolute;
    margin-top: 2px;
    padding: 10px;
    width: 95%;
    z-index: 100;
    cursor: pointer;
    transition: transform 0.2s linear;
    background: #f4f3ef !important;
    /* background-color: red; */
  }
 
  .head:hover {
    color: #008a7e;
    text-decoration: underline;
    cursor: pointer;
  }
 
 
  
  .radio {
    display: none;
  }
  
  .radio:checked + .relative .head {
    cursor: pointer;
    padding: 0;
    font-weight: 700;
    margin-top: 5px;
    transform: translateX(42px);
  }
  .radio:checked + .relative .circle {
    background: #f98262;
  }
 
  
  .radio:checked ~ .content {
    max-height: 80vh;
    overflow-y: scroll;
    border-color: #48b379;
    margin-right: 20px;
    transform: translateX(20px);
    transition: max-height 0.4s linear, border-color 0.5s linear, transform 0.2s linear;
  }
  .radio:checked ~ .content p {
    max-height: 200px;
    color: black;
    transition: color 0.3s linear 0.3s;
  }
  
  /* -------------------------------------
   * mobile phones (vertical version only)
   * ------------------------------------- */
  @media screen and (max-width: 767px) {
    #timeline {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
    #timeline li {
      margin: 50px 0;
    }
  
    .head {
      width: 85%;
      font-size: 1.1em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      transform: translateX(18px);
    }
  
    .content {
      padding-top: 45px;
      border-color: #eee9dc;
    }
    .content:before, .content:after {
      border: solid transparent;
      bottom: 100%;
    }
    .content:before {
      border-bottom-color: inherit;
      border-width: 17px;
      top: -16px;
      left: 50px;
      margin-left: -17px;
    }
    .content:after {
      border-width: 20px;
      top: -20px;
      left: 50px;
      margin-left: -20px;
    }
    .content p  {
      font-size: 0.9em;
      line-height: 1.4;
    }
  
    .circle, .date {
      display: none;
    }
  }