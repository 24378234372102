.payment-cont{
  padding-top: 100px;
}

.card-b {
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
  -webkit-transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease;
  -webkit-transition: box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
  transition: box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
  transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease;
  transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
}
/* .card-b {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border-radius: .25rem;
} */
.card-stats .card-body {
  padding: 15px 15px 0;
}

.card-b .card-body {
  padding: 15px 15px 10px;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-stats .card-footer {
  padding: 0 15px 15px;
}
.card-b .card-footer {
  background-color: initial;
  border: 0;
}
.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}
.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0,0,0,.03);
  border-top: 1px solid rgba(0,0,0,.125);
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

.card-stats .icon-big i {
  line-height: 59px;
}
.text-warning, a.text-warning:focus, a.text-warning:hover {
  color: #fbc658!important;
}
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 nucleo-icons;
  font-size: inherit;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-warning {
  color: #ffc107!important;
}

.nc-globe:before {
  content: "\EA2E";
}

.card-stats .card-footer .stats {
  color: #9a9a9a;
}

.card-b .card-footer .stats i {
  margin-right: 9px;
  position: relative;
  top: 0;
  color: #66615b;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-refresh:before {
  content: "\f021";
}

.payment-course-heading{
    color: #4d4d4d;
    font-size: 2.3rem;
    padding-left: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid #deddda;
}

.card-stats .card-body .numbers .card-category {
  color: #9a9a9a;
  font-size: 16px;
  line-height: 1.4em;
}
.card-stats .card-body .numbers p {

  margin-bottom: 0;
  color: #4d4d4d;
}
@media (max-width: 1300px){
  .card-stats .card-body .numbers p {
    font-size: 1.5rem;
  }
}
.card-category {
  font-size: 1em;
}
.card-category, .category {
  text-transform: capitalize;
  font-weight: 400;
  color: #9a9a9a;
  font-size: .7142em;
}

.card-font-size-align{
  text-align: right;
  font-size: 35px;
}
