.cardBody2{
    width: 570px;
    height: 340px;
    padding: 0px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 3px solid #00ce9f;
    border-bottom: 3px solid #00ce9f;
    min-width: 570px;
    max-width: 570px;
}
.status{
    text-align: center;
    margin-top: 30px;
    margin-bottom: -12px;
    margin-left: 50px;
    margin-right: 50px;
    color: red;
    font-weight: 400;
    font-size: 1.2em
}
.statusRed{
    text-align: center;
    margin-top: 30px;
    margin-bottom: -12px;
    margin-left: 50px;
    margin-right: 50px;
    color: red;
    font-weight: 400;
    font-size: 1.2em
}
.cardBody2 h1{
    display: inline-block;
    /* width: 50px; */
    font-size: 24px;
    margin-left: 10%;
    color: #017969;
    font-weight: 900;
}
.id-card-2-h1{
    margin-left: 15% !important;
}
.logoImage{
    height: 103px;
    margin: 15px 40px 0px 25px;
}
.idCardLocation a{
    color: black;
    text-decoration: underline !important;
}
.admit-logoForm b{
    color: #017969;
}
.admit-logoForm{
    margin-top: 15%;
}
.admit-logoForm p{
    color: black; 
    margin-top: -3%;
    font-size: 1.1rem;
    font-weight: bolder;

}

.cardImage{
    margin: 5%;
    margin-left: 10%;
    border: 1px solid #00ce9f;
}


.UserAuthCard b{
    color: #017969;
}
.UserAuthCard{
    margin-top: 15%;
}
.UserAuthCard p{
    color: black;
    /* margin-top: -3%; */
    font-weight: bolder;
    font-size: 1.1rem;
    margin-top: 67%;
    /* padding-top: 65%; */
    width: 130%;
    padding-top: 4px;
    border-top: 1px solid;
    /* border: 1px solid; */
    margin-left: -35%;
    padding-left: 10%;
}


@media screen and (max-width: 650px){
    /* .copyright{
        display: none;
    } */
}
/* Loader */
.LoaderContainer{
    width:100%;
    height:100vh;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:1111111;
    background: rgba(255, 241, 241, 0.534)
  }
  .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
  }

  .lds-resend{
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-resend div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #017969;
    animation: lds-resend 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-resend div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
  }
  .lds-resend div:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
  }
  .lds-resend div:nth-child(3) {
    left: 45px;
    animation-delay: 0;
  }
  @keyframes lds-resend {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }
  
  

  @media print {
    .hideOnPrint{
        display: none;
    }

}  