.ChangePassword-from-div{
  max-width: 552px;
  width: 100%;
  min-height: auto;
  border-radius: 5px;
  /* box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16); */
  /* background-color: #ffffff; */
  margin: 0px auto 0px auto;
  padding-bottom: 20px;
}

.ChangePassword-from-div .form-control, .ChangePassword-from-div .AdminLogin-submit-button{
  background-color: #f4f3ef !important;
}

.ChangePassword-from-div .AdminLogin-text-light-green, .alert-passchanged{
  color: #01645f;
}

.ChangePassword-from-div .AdminLogin-submit-button{
  color: #01645f;
    border: 1px solid #01645f;
}

.ChangePassword-from-div .AdminLogin-submit-button:hover{
  color: #f4f3ef;
    border: 1px solid #01645f;
    background: #01645f !important;
}
.error{
  color: red;
}
/* 
.alert-passchanged{
  color: blue;
} */