.bookCoverDiv{
  background: #fff;
  /* background-image: url(https://cdn1.iconfinder.com/data/icons/adobe-acrobat-pdf/154/pdf-book-ebook-acrobat-file-article-text-512.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-shadow: 0 0 4px 0 rgba(17,22,26,.16), 0 2px 4px 0 rgba(17,22,26,.08), 0 4px 8px 0 rgba(17,22,26,.08);
  border-radius: 6px;
  width: 120px;
  height: 180px;
  margin: 10px 0px;
  /* transition: box-shadow .2s ease-in-out; */
  cursor: pointer;
  transition: 1s;
}

.bookCoverDiv:hover{
  transform: scale(1.1);
  transition: 1s;
}

.bookCoverDiv-cont{
  flex-direction: column;
}

.max-w-150{
  max-width: 150px !important;
}

.bookCoverDivP{
  font-size: 14px;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
}

.bookCoverDivP:hover{
  color: #01645f;
}