.online-lec-cont{
  /* border: 1px solid black; */
  max-width: 1024px;
  margin: auto;
}

.online-lectures{
  padding-top: 20px;
}

.onlinelec-course-card{
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(17,22,26,.16), 0 2px 4px 0 rgba(17,22,26,.08), 0 4px 8px 0 rgba(17,22,26,.08);
  border-radius: 6px;
  width: 470px;
  margin: 10px 0px;
  transition: box-shadow .2s ease-in-out;
  width: 100%;
}

.onlinelec-course-card-sec1{
  line-height: 1.6em;
  padding: 2rem 4rem 1rem 4rem;
}
.onlinelec-course-card-sec1-course{
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  color: #58646d;
}
.onlinelec-course-card-sec1-title{
  font-weight: 600;
  color: #2e3d49;
  font-size: 1.125rem;
  padding-bottom: 10px;
  line-height: 1.75rem;
}
.onlinelec-course-card-sec1-desc{
  max-width: 35em;
  display: -webkit-box;
  line-height: 1.6em;
  position: relative;
  width: 100%;
  font-size: .875rem;
}

.onlinelec-course-card-sec2{
  border-top: 1px solid #dbe2e8;
    display: flex;
    padding: .875rem;
    line-height: 1.33333em;
    margin: 0;
}

.onlinelec-course-card-sec2-btn{
  margin-left: auto;
  color: #52c9ba;
  font-family: Open Sans,sans-serif;
  /* border: 2px solid #00ce9f; */
  border: 1px solid #52c9ba;
  font-size: .75rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  height: 2.3rem;
  padding: 0px 2rem;
  background: #fff;
  /* border: transparent; */
  cursor: pointer;
  box-shadow: 0 0 8px 0 rgba(17,22,26,.16), 0 4px 8px 0 rgba(17,22,26,.08), 0 8px 16px 0 rgba(17,22,26,.08);
}

.online-lectures-heading{
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  color: #2e3d48;
}