@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
.p-1 {
  padding: 5px !important;
}
.p-2 {
  padding: 15px !important;
}
.p-3 {
  padding: 25px !important;
}
.p-4 {
  padding: 35px !important;
}
.p-5 {
  padding: 45px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pl-2 {
  padding-left: 15px !important;
}
.pl-3 {
  /* padding-left: 25px !important; */
}
.pl-4 {
  padding-left: 35px !important;
}
.pl-5 {
  padding-left: 45px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pr-2 {
  padding-right: 15px !important;
}
.pr-3 {
  padding-right: 25px !important;
}
.pr-4 {
  padding-right: 35px !important;
}
.pr-5 {
  padding-right: 45px !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pt-2 {
  padding-top: 15px !important;
}
.pt-3 {
  padding-top: 25px !important;
}
.pt-4 {
  padding-top: 35px !important;
}
.pt-5 {
  padding-top: 45px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pb-2 {
  padding-bottom: 15px !important;
}
.pb-3 {
  padding-bottom: 25px !important;
}
.pb-4 {
  padding-bottom: 35px !important;
}
.pb-5 {
  padding-bottom: 45px !important;
}
.px-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-2 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-3 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.px-4 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.px-5 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.py-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-2 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-3 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.py-4 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-5 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.m-1 {
  margin: 5px !important;
}
.m-2 {
  margin: 10px !important;
}
.m-3 {
  margin: 15px !important;
}
.m-4 {
  margin: 25px !important;
}
.m-5 {
  margin: 35px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.ml-4 {
  margin-left: 25px !important;
}
.ml-5 {
  margin-left: 35px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mr-4 {
  margin-right: 25px !important;
}
.mr-5 {
  margin-right: 35px !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 25px !important;
}
.mt-5 {
  margin-top: 35px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.mb-4 {
  margin-bottom: 25px !important;
}
.mb-5 {
  margin-bottom: 35px !important;
}
.mx-1 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-3 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mx-4 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.mx-5 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}
.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-3 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.my-4 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.my-5 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
body {
  /* background-color: lightgray; */
  font-family: 'Open Sans', sans-serif;
}
.wp-content {
  margin-top: 60px;
  margin-left: 75px;
}
/* Remove unde
 a:hover {
 text-decoration: none;
 }
 /* Remove Bootstrap button outline */
button .focus, a .focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button:focus, a:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
[data-component='navbar'] {
  /* Right Menu Dropdowns */
  /* Notification */
  /* Notification Dropdown */
}
[data-component='navbar'] .navbar {
  background-color: #fff;
}
[data-component='navbar'] .navbar-toggler {
  line-height: 2.4;
  margin-left: 75px;
}
[data-component='navbar'] .navbar-toggler span {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  display: none;
}
[data-component='navbar'] .navbar-toggler[aria-expanded="true"] {
  color: #01645f;
}
[data-component='navbar'] .navbar-toggler[aria-expanded="true"] span {
  display: initial;
  color: #01645f;
}
[data-component='navbar'] .navbar-toggler[aria-expanded="true"] i {
  color: #01645f;
}
[data-component='navbar'] .navbar-collapse {
  background-color: #292a2c;
  margin-left: 75px;
}
[data-component='navbar'] .navbar-nav {
  height: 100vw;
}
[data-component='navbar'] .collapsing {
  -webkit-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
[data-component='navbar'] .navbar-brand {
  background-color: #01645f;
  height: 60px;
  width: 75px;
  text-align: center;
}
@media (max-width: 500px){
  [data-component='navbar'] .navbar-brand {
    width: 50px;
  }
}
[data-component='navbar'] ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
[data-component='navbar'] .right-links i {
  color: #000;
  font-size: 20px;
  line-height: 2.8;
}
[data-component='navbar'] .home {
  border-right: 1px solid #ababab;
}
[data-component='navbar'] .dropdown-toggle::after {
  display: none;
}
[data-component='navbar'] .dropdown-item {
  color: #292b2c;
  font-size: 0.75em;
}
[data-component='navbar'] .dropdown {
  position: relative;
}
[data-component='navbar'] .dropdown span {
  color: #fff;
  position: absolute;
  width: 15px;
  height: 15px;
  top: -8px;
  right: -3px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  font-size: 0.625em;
  font-weight: 600;
}
[data-component='navbar'] .dropdown img {
  border-radius: 50%;
  height: 40px;
}
[data-component='navbar'] .list-group .lg {
  height: 500px;
  width: 270px;
  overflow: auto;
}

.list-group-item{
  white-space: nowrap;
  /*line-height: 60px;*/
}
[data-component='navbar'] .list-group .list-group-item {
  
  border-radius: 0;
  letter-spacing: 0.5px;
}
[data-component='navbar'] .list-group h5 {
  font-size: 0.6875em;
  font-weight: 700;
  line-height: 2;
}
[data-component='navbar'] .list-group p {
  font-size: 0.5625em;
  font-weight: 400;
  line-height: 2;
}
[data-component='navbar'] .megamenu #menuOne, [data-component='navbar'] .megamenu #menuTwo, [data-component='navbar'] .megamenu #menuThree {
  position: absolute;
  width: 100%;
  z-index: 1;
}
[data-component='navbar'] .megamenu .megamenu-links {
  background-color: #eee;
  height: 100vh;
  overflow: hidden;
}
[data-component='navbar'] .megamenu .btn {
  font-size: 0.875em;
  font-weight: 400;
  letter-spacing: 0.5px;
}
[data-component='navbar'] .megamenu .btn:hover {
  background-color: #292a2c;
  color: #fff;
}
[data-component='navbar'] .megamenu a[aria-expanded=true] .fa-plus {
  display: none;
}
[data-component='navbar'] .megamenu a[aria-expanded=false] .fa-minus {
  display: none;
}
[data-component='navbar'] .megamenu [aria-expanded="true"] {
  background-color: #292a2c;
  color: #fff;
}
[data-component='navbar'] .megamenu [aria-expanded="true"] i {
  color: #fff;
  font-size: 1.25em;
}
[data-component='navbar'] .megamenu [aria-expanded="false"] {
  background-color: #eee;
  color: #292a2c;
  border-radius: 0;
}
[data-component='navbar'] .megamenu [aria-expanded="false"] i {
  color: #01645f;
  font-size: 1.25em;
}
[data-component='navbar'] .megamenu .list-group {
  height: 100vw;
  background-color: #292a2c;
}
[data-component='navbar'] .megamenu .list-group-item {
  border-radius: 0;
  background-color: #292a2c;
  font-size: 0.75em;
  color: #fff;
}
[data-component='navbar'] .megamenu .list-group-item:hover {
  background-color: #01645f;
}
[data-component='sidebar'] .sidebar {
  position: fixed;
  z-index: 111113;
}
[data-component='sidebar'] .first-menu, [data-component='sidebar'] .first-menu2 {
  position: fixed;
  background-color: #292a2c;
  height: 100vh ;
  width: 75px;
  top: 60px;
  overflow: hidden;
  transition: width 0.5s;
}

@media (max-width: 500px){
  [data-component='sidebar'] .first-menu, [data-component='sidebar'] .first-menu2 {
    width: 50px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

[data-component='sidebar'] .first-menu a, [data-component='sidebar'] .first-menu2 a {
  color: #fff;
}
[data-component='sidebar'] .first-menu i, [data-component='sidebar'] .first-menu2 i {
  font-size: 24px;
}
[data-component='sidebar'] .first-menu span, [data-component='sidebar'] .first-menu2 span {
  font-size: 14px;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  opacity: 0;
  visibility: hidden;
}
[data-component='sidebar'] .first-menu:hover span {
  opacity: 1;
  visibility: visible;
}
[data-component='sidebar'] .first-menu .list-group-item, [data-component='sidebar'] .first-menu2 .list-group-item  {
  border-radius: 0;
}
[data-component='sidebar'] .first-menu .list-group-item:hover {
  background-color: #01645f;
}
[data-component='sidebar'] .first-menu:hover {
  width: 180px;
}
[data-component='sidebar'] .list-group-item {
  font-size: 14px;
}
[data-component='sidebar'] .submenu {
  position: fixed;
  left: 180px;
  width: 195px;
  top: 60px;
  background-color: #01645f;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: 100ms opacity 100ms, 100ms visibility 100ms;
}
[data-component='sidebar'] .submenu .list-group-item {
  /* margin-top: 8px; */
}
[data-component='sidebar'] .first-menu li:hover .submenu {
  visibility: visible;
  opacity: 1;
}
[data-component='sidebar'] .sub-submenu, [data-component='sidebar'] .sub-submenu2 {
  position: fixed;
  background-color: #fff;
  left: 355px;
  top: 60px;
  height: 100vw;
  width: 195px;
  opacity: 0;
  visibility: hidden;
  transition: 600ms opacity 600ms, 600ms visibility 600ms;
}
[data-component='sidebar'] .sub-submenu a {
  color: #01645f;
}
[data-component='sidebar'] .sub-submenu .list-group-item:hover {
  /* background-color: transparent; */
}
[data-component='sidebar'] .submenu li:hover .sub-submenu {
  visibility: visible;
  opacity: 1;
}
[data-component='sidebar'] .list-group-item {
  background-color: transparent;
  border: none;
  border-radius: 0;
}
[data-component='sidebar'] .arrow {
  position: fixed;
  left: 329px;
  width: 0;
  height: 0;
  border-top: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-right: 27px solid white;
}
[data-component='sidebar'] .arrow:nth-child(3) {
  top: 20px;
}
.fixed-top {
  z-index: 111114 !important;
}


.nav-content{
  box-shadow: 0px 0px 20px -6px grey !important;
}


@media (max-width: 500px){
  [data-component='sidebar'] .first-menu .list-group-item{
    /* height: 0; */
  }
  [data-component='sidebar'] .first-menu .list-group-item:hover{
    display: grid;
    height: auto;
  }
  [data-component='sidebar'] .sub-submenu .list-group-item:hover {
    background-color: black !important;
  }
  [data-component='sidebar'] .submenu{
    margin-left: -15px;
  width: 180px;
  /* height: auto; */
  position: initial;
  background-color: #0000001c;
  height: 0;
  overflow: hidden;
  }
  [data-component='sidebar'] .first-menu li:hover .submenu {
    height: auto;
  }
  [data-component='sidebar'] .list-group-item{
    font-size: 10px;
  }
  [data-component='sidebar'] .first-menu span, [data-component='sidebar'] .first-menu2 span{
    font-size: 10px;
  }

}