.sidebar{
  color: white;
}
/* .sidebar i{
  cursor: pointer;
} */
.sidebar a{
  cursor: pointer;
  font-style: normal;
}

.sidebar i{
  font-style: normal;
}

.sidebar-container{
  width: 300px;
  padding-top: 80px;
}

.sidebar-image{
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 50%;
  border: 3px solid #00645f;
}

.sidebarInfoNameHeading{
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 800;
  color: gray;
  margin-bottom: 0;
}

.editProfileHeading { 
  text-align: center;
  font-size: 16px;
  color: #01645f;
}

.expandableTabInfo{
  width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}
.expandableTabInfo table{
  width: 100%;
  font-size: 13px;
}
.expandableTabInfo .td-Left{
  width: 30%;
  background-color: #00645f24;
  padding: 5px 5px 5px 10px;
  font-weight: 900;
  color: #5c5c5c;
  min-width: 90px;
}
.expandableTabInfo .td-right{
  width: 70%;
  background-color: #dcdcdc47;
  padding: 5px 5px 5px 10px;
  color: gray;
}

/* .MuiExpansionPanelSummary-content-56{
  margin: 0 !important;
} */
/*
 *  STYLE 3
 */

.expandableTabInfo::-webkit-scrollbar-track
 {
   /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
   background-color: #F5F5F5;
 }
 
.expandableTabInfo::-webkit-scrollbar
 {
   height: 6px;
   background-color: #F5F5F5;
 }
 
.expandableTabInfo::-webkit-scrollbar-thumb
 {
   background-color: #00645f24;
 }
 
.MuiExpansionPanelSummary-content-127{
  margin: 0 !important;
}


.sidebar-menu-icon{
  width: 40px;
}

.sidebarleft-materialIcon{
  opacity: 1;
  visibility: visible;
  display: inline;
  color: white;
  font-size: 45px;
}


@media (max-width: 500px){
  .sidebar-menu-icon{
    width: 25px;
  }
  .sidebarleft-materialIcon{
    font-size: 25px;
  }
}