.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
*{
  scroll-behavior: smooth;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button{
  cursor: pointer;
}

body{
  background: #f4f3ef !important;
}
.App-link {
  color: #61dafb;
}

.routejs-row{
  margin: 0 !important;
}

.sidebarContWidth{
  width: 75px;
}

@media (max-width: 379px){
.padding-left-on-mobile{
  /* padding-left: 50px !important; */
}
}

@media (max-width: 500px){
  .sidebarContWidth{
    width: 50px;
  }
  
}

.padding-0-imp{
  padding: 0 !important;
}
.padding-0-imp-l-75px{
  padding: 0 0 0 75px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pos-abs-imp{
  position: absolute !important;
}



.wid100-75px{
  width:100%;
  padding: 0 2% 0 2%;
}
.wid100{
  width: calc(100% - 75px);
  padding: 0 2% 0 2%;
  
}


 /* width height classes */
.height-10{
  height: 10%;
}
.height-20{
  height: 20%;
}
.height-30{
  height: 30%;
}
.height-40{
  height: 40%;
}
.height-50{
  height: 50%;
}
.height-60{
  height: 60%;
}
.height-70{
  height: 70%;
}
.height-80{
  height: 80%;
}
.height-90{
  height: 90%;
}


.hw{
  width: 100%;
}
.hh{
  height: 100%;
}


.width-10{
  width: 10%;
}
.width-20{
  width: 20%;
}
.width-30{
  width: 30%;
}
.width-40{
  width: 40%;
}
.width-50{
  width: 50%;
}
.width-60{
  width: 60%;
}
.width-70{
  width: 70%;
}
.width-80{
  width: 80%;
}
.width-90{
  width: 90%;
}

/* height width end */

.MuiCircularProgress-colorSecondary-7{
  color: #01645f !important;
}

button{
  outline-style: none;
}


.test-border {
  border: solid 2px red !important;
}

.flexer { 
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.flexer-column { 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}




/* .paddingLeftForWidth{
  padding-left: 70px;
} */


@media (max-width : 1157px){
  .paddingLeftForWidth{
    padding-left: 70px;
  }
}


@media (max-width : 500px){
  .paddingLeftForWidth{
    padding-left: 50px;
  }
}