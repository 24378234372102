
.course-sections{
  width: calc(100% + 30px);
  margin-left: -17px;
}

.course-sections-cont{
  width: 100%;
  height: 100%;
  padding: 0 13px;
  background-color: #f4f3ef;
}

.course-sections-sidebar{
  background-color: #292a2c;
  min-height: calc(100vh - 60px);
}

@media(max-width: 767px){
  .course-sections-sidebar{
    background-color: #292a2c;
    min-height: auto;
  }
}

.course-sections-card-body{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(17,22,26,.16), 0 2px 4px 0 rgba(17,22,26,.08), 0 4px 8px 0 rgba(17,22,26,.08);
    max-width: 600px;
    margin: 10px auto;
    padding: 0 15px;
    overflow: hidden;
}

.course-sections-card-body-left{
  color: #2e3d49;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  overflow: hidden;
  line-height: 1.33333em;
  margin: 0;
  padding: 0;
}

.course-sections-card-body-sec1{
  padding: 3rem 3rem .875rem;
}

.course-sections-card-body-sec2{
  padding: 0 2.625rem .875rem 2.625rem;
}

.course-sections-card-body-sec1-heading{
  margin-bottom: .875rem;
}
.course-sections-card-body-sec1-heading-head h5{
  text-transform: uppercase;
  color: #2e3d49;
  font-size: .8125rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
}
.course-sections-card-body-sec1-heading-head{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.course-sections-card-body-sec1-heading-foot{
  font-size: 18px;
  font-weight: 600;
  color: rgb(46, 61, 73);
}


.course-sections-card-body-sec1-summary{
  line-height: 1.33333em;
    margin: 0;
    padding: 0;
}

.course-sections-card-body-sec1-summary-body{
  font-size: 1rem;
  line-height: 1.7;
}

.course-sections-card-body-sec1-summary-body-p{
  display: -webkit-box;
    height: 4.5em;
    line-height: 1.5em;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 35em;
    font-size: 1rem;
}

.course-sections-card-body-sec2-action{
  display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.33333em;
    margin: 0;
    padding: 0;
}

.course-sections-card-body-sec2-action button{
  color: #55cabb;
  margin: 1.3125rem 0;
  padding: .4375em .875em;
  text-transform: uppercase;
  box-shadow: 0 0 8px 0 rgba(17,22,26,.16), 0 4px 8px 0 rgba(17,22,26,.08), 0 8px 16px 0 rgba(17,22,26,.08);
  background: #fff;
  border: 1px solid #52c9ba;
  font-family: Open Sans,sans-serif;
    font-size: .75rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    border-radius: .25rem;
}

.course-sections-card-body-sec2-action button span{
  width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
}

.vds-icon{
  order: 2;
  margin-right: 0;
  margin-left: .5rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0;
  fill: currentColor;
  stroke: currentColor;
  display: inline-block;
  vertical-align: middle;
}


.course-sections-card-body-sec2-progress{
  border-top: 1px solid rgba(219,226,232,.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: .875rem 0;
}

.course-sections-card-body-sec2-progress-duration{
  letter-spacing: normal;
  color: #7d97ad;
  font-weight: 600;
  font-size: .75rem;
  line-height: 16px;
}


.greenGradientBg-card{
  background: linear-gradient(190deg,#08d88d6b,rgba(14, 181, 164, 0.78));
}


.min-height-50{
  min-height: 50px;
}


.course-sections-sidebar-list{
  padding: 1em 0;    
  line-height: 1.33333em;
  margin: 0;
  list-style-type: none;
}

.course-sections-sidebar-list-li{
  line-height: 2em;
  border-bottom: 1px solid #01ccb940;
}
.course-sections-sidebar-list-li:hover{
  background: linear-gradient(190deg,#08d88d6b,rgba(14, 181, 164, 0.78));
  color: white;
}

.course-sections-sidebar-list-li-selected{
  background: linear-gradient(190deg,#08d88d26,rgba(14, 181, 164, 0.26));
  color: white;
  border: 0;
}


.tree-concepts{
  margin: 0 -15px;
}
.course-sections-sidebar-list-li a{
  color: hsla(0,0%,100%,.5);
  display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1em;
    font-size: .875rem;
    font-weight: 400;
    text-decoration: none;
}
.course-sections-sidebar-list-li a:hover{
  color: white;
  text-decoration: none;
}

.course-sections-sidebar-list-li-span{
  color: #02ccba;    
  margin-right: .875rem;
  display: inline-block;
}
.vds-icon--sm {
  width: 1.5rem;
  height: 1.5rem;
}


.course-sections-sidebar-heading-cont h1{
  font-size: 1.5rem;
  padding: 20px 0px 0px 0px;
  color: gray;
  margin: 0;
}