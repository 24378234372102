.vimeoVideo-cont{
  background-color: #f4f3ef;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 0 15px;
  padding-bottom: 50px;
}

.vimeoVideoIframe{
  width: 100%;
  height: 40vw;
}

.nextPrvBtn button{
  padding: .5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .8125rem;
  -webkit-font-smoothing: antialiased;
  border-radius: .25rem;
  letter-spacing: .125rem;
  transition: .3s;
  box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15);
  color: #03ccb9;
  background-color: transparent;
  border: .125rem solid #03ccb9;
  margin-top: 10px;
  outline: 0 !important;
  white-space: nowrap;
}

.nextPrvBtn button span{
  vertical-align: text-bottom;
}


@media(max-width: 767px){
  .vimeoVideoIframe{
    /* width: 100%; */
    height: 45vw;
    margin-top: 15px;
  }
  .nextPrvBtn button{
    font-size: 2vw;
    padding: .3rem 1.2rem;
  }
  
}

.topicData-title{
  color: #00645f;
  font-size: 2rem;
  margin-bottom: 20px;
  /* margin-top: 20px; */
  font-family: myFontBold;
}

.topicData-desc{
  color: #3b3b3d;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  /* background-color: #25453f0f; */
  /* padding: 10px; */
  margin-bottom: 20px;
}

.vimeoVideo-cont-video{
  background-color: #efefeb;
}

@media (max-width: 600px){
  .topicData-title{
    font-size: 1rem;
  }
  
  .topicData-desc{
    font-size: 10px;
  }
}
.topicData-desc::-webkit-scrollbar
{
  width: 1px;
  height: 5px;
	background-color: #25453f05;
}

.topicData-desc::-webkit-scrollbar-thumb
{
	background-color: #00645f;
}
.topicData-desc::-webkit-scrollbar-track
{
	/* width: 12px; */
	/* background-color: black; */
}


.topicData-desc h1{
  font-size: 2rem;
}
.topicData{
  background-color: #25453e05;
    padding: 20px;
}