.nav-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  min-width: 350px;
  align-items: center
}

.navbar-brand{
  cursor: pointer;
}

.logout-btn{
  display: inline;
  font-size: 22px;
  border-right: 2px ridge;
  padding-right: 14px;
  font-variant: all-petite-caps;
  cursor: pointer;
}
.logout-icon{
  width: 15px;
  height: 15px!important;
  margin-left: 2px;
  margin-top: 2px;
}

.nav-content a{
  cursor: pointer;
}

.piaicHeadingNavbar{
      /* width: 80%; */
      font-size: 2.5vw;
      color: #00645f;
      opacity: 0.2;
      font-variant: all-small-caps;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 900;
}


.navbarBg{
  background-position: center;
    background-size: 60vw;
    background-repeat: no-repeat;
}
@media (max-width: 600px){
  .navbarBg{
    background-image: none !important;
  }
}
