/* @media (max-width: 905px){ */
  .PaymentHistoryTable-cont{
    padding-left: 80px;
  }
/* } */

.reload{
  text-align: center;
  font-size: 20px;
  color: #00645f;
  cursor: pointer;
  text-decoration: underline;
  margin-top: -15px;
}

.reload:hover{
  color: #00645fbf;
}