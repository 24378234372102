/* you can make up upload button and sample style by using stylesheets */
.main-doc-card {
    max-width: 552px;
    width: 70%;
    min-height: auto;
    border-radius: 5px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin: 0px auto 0px auto;
    padding: 20px;
}

@media (max-width: 576px) {
  .doc-upload-head {
  height: 100%;
  }
  }

  .document-upload-btn{
      margin-top: 5px;
      max-width: 250px;
      width: 40%;
      height: auto;
      padding: 10px;
      border-radius: 100px;
      color: white;
      transition: 0.5s;
      font-weight: bold;
      outline-style: none !important;
      border: 1px solid #0eb5a3;
      cursor: pointer;
      background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  }

.document-upload-btn:hover{
  transition: 0.5s;
  color: #00CE9F;
  background-color: transparent;
  border: solid 1px #00CE9F;
  background-image: linear-gradient(to bottom, white)

}

.skip-btn{
  margin-top: 5px;
  max-width: 250px;
  width: 40%;
  height: auto;
  padding: 10px;
  border-radius: 100px;
  color: #0eb5a3;
  transition: 0.5s;
  font-weight: bold;
  border: solid 1px #00CE9F;
  outline-style: none !important;
  cursor: pointer;
  background-color: transparent;
}

.skip-btn:hover{
transition: 0.5s;
color: white;
background-color: transparent;
background-image: linear-gradient(to left, #08d88d, #0eb5a3);
}



.doc-upload-head {
  max-width: 552.2px;
  width: 100%;
  height: 150px;
  background-color: #017969;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0px 0px;
}

.qualification-select { 
  padding: 10px; 
  width: 100%;
  border: solid 1px #017969;
}


.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

.ant-upload-list-item-error {
    border-color: lightgray !important;
  }