.QuarterJsBody .MuiCircularProgress-colorPrimary-6,
.VoucherFormBody .MuiCircularProgress-colorPrimary-6
{
  color: #01645f !important;
}


.fullPageLoaderContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #00000036;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111200;
}


.fullPageLoaderContainer .MuiCircularProgress-colorPrimary-5{
    color: #01645f !important;
}