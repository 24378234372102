.paid-bg{
  background-color: #00ff7417;
}
.pending-bg{
  background-color: #ff000017;
}
.locked-bg{
  background-color: #05050517;
  cursor: not-allowed;
}

.voucher-created{
  background-color: #f2ff0017;
}
.note{
  font-size : 1.2em;
  color : red;
  padding : 10px;
  margin: 0;
}
.voucherBranchesPageAddress{
  font-size : 1.2em;
  /* color : red; */
  padding : 10px;
  margin: 0;
}
.pending-fee-status{
  color : red
}

