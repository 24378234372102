.Homepage-heading{
  font-size: 4vw;
    text-align: center;
    color: #01645f;
    font-weight: 900;
    font-variant: petite-caps;
}

.current-status-heading{
  font-size: 3vw;
    text-align: center;
    color: #01645f;
    font-weight: 900;
    font-variant: petite-caps;
}
.voucher-not-paid-line{
  font-size: 2vw;
    color: gray;  
    text-align: center;
    font-weight: 900;
    font-variant: petite-caps;
}

.Homepage-click-forpayment{
  font-size: 2.5vw;
  text-align: center;
  color: #6d6d6d;
  /* font-weight: 900; */
  /* -webkit-font-feature-settings: "pcap"; */
  /* font-feature-settings: "pcap"; */
  font-variant: petite-caps;
  cursor: pointer;
  text-decoration: underline;
}

.Homepage-onlineInfo{
  font-size: 1.5vw;
  text-align: center;
  color: #6d6d6d;
}

.Homepage-click-forpayment:hover{
  color: #2d2d2d;
}

.Homepage-links-cont{
  margin: 25px auto;
  color: #00645f;
  /* width: 70%;
  max-width: 500px;
  overflow-x: scroll;
  overflow-y: hidden; */
}
.Homepage-links-list:hover {
  background: linear-gradient(80deg,transparent,rgba(14, 181, 164, 0.16),transparent);
  cursor: pointer;
}

.Homepage-links-cont ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.Homepage-links-list a{
  width: 100%;
  background: lightgray;
  white-space: nowrap;
  font-weight: 600;
}
.Homepage-links-cont table{
  margin: auto;
  /* border: 3px solid brown; */
  /* width: 100%; */
}
.Homepage-links-cont th{
  color: #00645f;
}


.Homepage-links-cont th, .Homepage-links-cont td{
  /* border: 1px solid green; */
  padding: 4px;
  white-space: nowrap;
}

.Homepage-links-cont img{
  width: 50px;
  /* margin: 10px; */
}

.Homepage-links-cont tr:hover{
  background: linear-gradient(80deg,transparent,rgba(14, 181, 164, 0.16),transparent);
  cursor: pointer;
}
@media (min-width: 600px){
  .Homepage-links-cont tr{
    display: inline-block;
    padding: 0 10px;
  }
  
  .Homepage-links-cont tr:nth-child(2){
    border-left: ridge;
    border-right: ridge;
  }
  .Homepage-links-cont tr:nth-child(3){
    /* border-left: ridge; */
    border-right: ridge;
  }
}
@media (max-width: 700px){
  .Homepage-links-cont img{
    width: 5vw;
    /* margin: 10px; */
  }
  .Homepage-links-cont{
    font-size: 2vw;
  }
  .Homepage-links-cont th, .Homepage-links-cont td{
    font-size: 2vw;
  }
  .Homepage-heading{
    font-size: 5vw;
  }
  .current-status-heading{
    font-size: 3.5vw;
  }
  .voucher-not-paid-line{
    font-size: 2.5vw;
  }
  .Homepage-click-forpayment{
    font-size: 3.5vw;
  }
}

.blurStatus{
  filter: blur(1px);
}

.statusAlertTable{
  width: 100%;
  white-space: nowrap;
  /* table-layout: fixed; */
}


.statusAlertTable td{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusAlertTable th{
  padding-right: 5px;
}

.statusPopoverContainer{
  /* width: 40%; */
  max-width: 300px;
}

.showStatusShow{
  cursor: pointer;
  color: #22605d;
  text-decoration: underline;
}
.result-main{
  margin: 30px;
}
.result{
  display: flex;
  justify-content: center
}
.result-field{
  font-size: 2vw;
  padding: 5px;
  font-weight: 500;

}
.result-field2{
  font-size: 2vw;
  padding: 5px;
  font-weight: 500;
  border-left: ridge;
}
.result-field3{
  font-size: 2vw;
  padding: 5px;
  font-weight: 500;
  color: mediumseagreen
}
.result-failed{
  font-size: 2vw;
  padding: 5px;
  font-weight: 500;
  color: lightcoral
}
.Homepage-click-voucher-notice{
  cursor: pointer;
  text-decoration: underline;
}